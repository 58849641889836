@import "./styles/tabs.css";
@import "@radix-ui/colors/black-alpha.css";
@import "@radix-ui/colors/mauve.css";
@import "@radix-ui/colors/violet.css";

.container {
    margin: auto;
    width: fit-content;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w3a-modal {
    background: #eaeaeaDD;
}

.rt-Button {
    white-space: nowrap !important;
}
.button {
    padding: 12px 32px;
    border-radius: 16px;
    /*background-color: rgb(19 35 29);*/
    color: #FFF;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    border: none;
}

.imgLink {
    cursor: pointer;
    transition: transfrom 200ms;
    &:hover {
        transform: scale(1.1);
    }
}

.button:hover {
    /*background: rgba(19, 35, 29, 0.7);*/
}

.w3a-header__logo img {
    width: auto !important;
}

a {
    /*color: #0B0D15;*/
    font-size: 14px;
    text-decoration: none;
}

.main {
    min-height: 100vh;
    padding: 4rem 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: 20px;
    font-weight: bold;
    margin-top: 0;
    text-align: center;
}

.title a {
    /*color: #0070f3;*/
    text-decoration: none;
}

.grid {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.torusIframe {
    display: none !important;
}

.card {
    margin: 0.5rem;
    padding: 0.7rem;
    text-align: center;
    /*color: #0070f3;*/
    background-color: #fafafa;
    text-decoration: none;
    /*border: 1px solid #0070f3;*/
    border-radius: 10px;
    transition: color 0.15s ease, border-color 0.15s ease;
    width: 100%;
}

.card:hover,
.card:focus,
.card:active {
    cursor: pointer;
    background-color: #f1f1f1;
}

.graph {
    width: 100%
}
.footer {
    display: flex;
    flex: 1;
    padding: 2rem 0;
    /*border-top: 1px solid #eaeaea;*/
    justify-content: center;
    align-items: center;
    margin-top: 10rem;
}

.footer a {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.logo {
    height: 1.5rem;
    margin-left: 0.5rem;
}

@media (max-width: 1200px) {
    .container {
        width: 100%;
    }
}

.flex-container {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    margin: 32px;
}

.flex-container > div {
    width: 100%;
    max-width: 300px;
    margin: 10px;
    text-align: center;
    line-height: 75px;
    font-size: 30px;
}

#console {
    width: 100%;
    height: 100%;
    overflow: auto;
    word-wrap: break-word;
    font-size: 16px;
    font-family: monospace;
}

.App {
    text-align: center;
}

.success-box {
    margin: 32px;
    padding: 8px 32px;
    border-radius: 8px;
    background-color: #4BB543;
    display: flex;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    /*background-color: #282c34;*/
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #FFF;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.MuiDataGrid-row {
    cursor: pointer;
}

.MuiDataGrid-cell:focus {
    outline: none !important;
}

.MuiDataGrid-virtualScroller {
    min-height: 64px;
}

.MuiDataGrid-selectedRowCount {
    visibility: hidden !important;
}

.text-bold {
    font-weight: bold;
}


.w-100 {
    width: 100%;
}

.fr {
    display: flex;
    flex-direction: row;

}

.fc {
    display: flex;
    flex-direction: column;
}

.aic {
    align-items: center;
}

.jcc {
    justify-content: center;
}

.gap-1 {
    gap: 1rem;
}

.gap-2 {
    gap: 2rem;
}

.w3a-parent-container button img {
    height: 32px;
}

.invert {
    filter: invert(1);
}
